const protocol = location.protocol;
const hostname = location.hostname;
const port = location.port ? ':' + location.port : '';

const baseUrl = `${protocol}//${hostname}${port}`;

export const environment = {
    production: false,
    imgUrl: `${baseUrl}/assets/img/marker_icons`,
    imgServicesBK: `${baseUrl}/assets/img/services_icons_bk`,
    postmanUrl: `${baseUrl}/abacusgis-pre`,
    postmanPort: '',
    postmanDelimitator: '',
    postmanHost: `${baseUrl}`,
    postmanContext: '/abacusgis-pre',
    postmanPortDev: '',
    urlExpansionReport: `${baseUrl}/expansion-report/dist/index.html`,
    urlPrescriptiveReport: `${baseUrl}/brick-report`
};
